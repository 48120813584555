@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    @apply hidden;
  }
}

/* text: #2e2c2c */
/* background: #f5f5f5 */
/* primary: #0268b6 */
/* secondary: #cccccc */
/* accent: #0681a3 */

@layer base {
  body {
    margin: 0;
    font-family: 'Poppins', 'Nunito Sans', 'Noto Sans Javanese', -apple-system,
      BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-[#2c2a2a] text-[#e8e8e8] tracking-wide;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  h3 {
    font-size: 2.369rem;
    @apply m-1 p-2;
  }
}
#home {
  display: block;
  position: relative;
  background: #5a7b8e;
  background: linear-gradient(0deg, #5a7b8e99 0%, #e3eef7e9 100%);
}
#about {
  display: block;
  position: relative;
  background: linear-gradient(180deg, #5a7b8e99 0%, #2c2a2a 70%);
}
#skills {
  display: block;
  position: relative;
}
.bg-custom-radial-gradient {
  background: radial-gradient(circle, #3bbaf9c9 0%, #00000000 80%);
}
#timeline {
  display: block;
  position: relative;
  background: linear-gradient(0deg, #4c4d4e 0%, #2c2a2a 50%);
}
#projects {
  display: block;
  position: relative;
  background: linear-gradient(0deg, #5a7b8e99 0%, #4c4d4e 100%);
  /* background: linear-gradient(0deg, #5a7b8e99 0%, #2c2a2a 70%); */

  /* background: linear-gradient(
    180deg,
    rgb(90, 123, 142) 6%,
    rgba(0, 0, 0, 0) 7%
  ); */
}
#contact {
  display: block;
  position: relative;
  background: linear-gradient(180deg, #5a7b8e99 0%, #e3eef7e9 100%);
}

/* About Section Image 3d CSS */
.profile-picture {
  perspective: 1000px;
}

#img3d {
  width: 100%;
  height: 100%;
  background: url('/public/Images/profile/bgBlur.png'); /* Add the URL of your background image */
  background-size: cover;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

#img3d img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0); /* Set the initial translateZ value to 0 */
  transition: transform 0.5s; /* Add a transition for the image */
}

#img3d:hover img {
  transform: translateZ(
    20px
  ); /* Increase the translateZ value when hovered to make it appear as if it's coming out of the background */
}
#img3d:hover {
  transform: rotateY(-20deg) rotateX(-20deg); /* Rotate the container when hovered */
}

/* carousel */
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

/* 3d css */

.animate-shake {
  animation-duration: 0.5s;
  animation-name: shake;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}
